.color-danger {
  color: rgb(228, 137, 0);
}

.modal-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.active-profile {
  position: absolute;
  inset: 0 0 auto auto;
  margin: 0;
  transform: translate(0.125px, 58px);
}

.dropdown-choose-date-shipment {
  position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(-1px, 31px);
}
