.qColorActive {
    color: #ea6c00;
}

.qIcon i {
    display: none;
}

.qIcon:hover {
    color: #ea6c00;
}

.qIcon:hover i{
    margin-left: 4px;
    display: initial !important;
}

.qIconActive:hover{
    color: #ea6c00;
}
