.spinner-border {
    width: 3rem;
    height: 3rem;
    border: 0.25em solid var(--main-zapp);
    border-right-color: transparent;
}
.alert-label-icon {
    border-radius: 15px;
}
.rounded-label .label-icon {
    width: 56px;
    height: 100%;
    border-radius: 15px;
}
.alert-success{
    background-color: #DBF7F4;
}
.alert-warning {
    color: #bf9049;
    background-color: var(--orange-lg-font-zapp);
}
.rounded-label .label-icon {
    font-size: 20px;
}
.rounded-label.alert-success .label-icon {
    background-color: #D1EFED;
    color: #013939;
}
.rounded-label.alert-warning .label-icon {
    background-color: rgba(255, 197, 66, 0.1);
    color: var(--main-zapp);
}
.alert .btn-close::after {
    color: #013939 !important;
}
.alert-dismissible .btn-close{
    top: -10px;
}
.alert .alert-text{
    color: #171725;
}
.h-55{
    height: 55px;
}
.max-w360{
    max-width: 360px;
}
.alert-info-zp{
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 999999999;
}
.modal-backdrop.show {
    opacity: .1;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    border-width: thin;
    left: 0;
    position: absolute;
}
.input-date{
    position: relative;
    padding-left: 28px;
}

/*loadding*/
.line {
    width: 100%;
    height: 4px;
    background-color: var(--main-zapp);
    animation: loading 2s linear;
    animation-iteration-count: infinite;
}
@keyframes loading {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}