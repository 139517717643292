/*page title name*/
.page-title-name{
    font-size: 36px;
    font-weight: 600;
}
/*paginate*/
.page-item.active .page-link{
    background-color: var(--main-zapp);
    color: #fff;
    border: none;
}
.page-item .page-link{
    color: var(--main-zapp);
}
.nav-success.nav-tabs-custom .nav-link.active{
    font-weight: 600;
    color: var(--main-zapp);
    background-color: transparent;
}
.nav-success.nav-tabs-custom .nav-link.active::after{
    background-color: transparent;
}
/*card-sell*/
.card-sell{
    max-width: 875px;
    margin: auto;
    padding: 30px 0;
    border-bottom: 1px solid var(--bg-br-ams);
}
.card-sell .img-sell {
    display: inline-block;
}
.card-sell .img-sell img{
    border-radius: 20px;
}

.siderbar-nav-left  ul {
    position: relative;
    display: flex;
    flex-direction: column;
}
.siderbar-nav-left  ul li {
    position: relative;
    list-style: none;
    line-height: 35px;
}
.siderbar-nav-left  ul li a {
    font-size: 14px;
    text-decoration: none;
    padding-left: 10px;
    color: var(--main-font-zapp);
}
.siderbar-nav-left  ul li::before{
    content: "";
    position: absolute;
    color: var(--main-bland-zapp);
    width: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    transition: 1s;
    border-right: 2px solid var(--cl-hover);
}
.siderbar-nav-left  ul li::after{
    content: "";
    position: absolute;
    color: var(--main-bland-zapp);
    width: 0;
    height: 100%;
    left: 0;
    z-index: -1 ;
    overflow: hidden;
    transition: 1s;
    border-right: 2px solid var(--main-light-zapp);
}
.siderbar-nav-left ul li.active::before{
    border-right: 2px solid  rgba(255, 197, 66, 0.8);
}

/*time line*/
.timeline-ct-2::after{
    top: -10px;
    background: var(--brown-zapp);
    left: 140px;
    height: 100%;
}
.timeline-ct-2 .timeline-date{
    margin: 0 ;
    margin-left: 25px;
    left: 160px;
}
.timeline-ct-2 .timeline-date::after{
    left: -10px;
    width: 20px;
    height: 20px;
    top: 0;
    background: var(--brown-zapp);
}
.bg-border-pd{
    background: #fff;
    padding: 20px 15px ;
    border-radius: 4px;
}
/*table*/
.table-none-pd-left th,
.table-none-pd-left td{
    padding-left: 0;
}

table thead tr th{
    color: var(--main-font-zapp);
    font-weight: 600 !important;
    background-color: #ffffff !important;
    font-size: 12px;
}

/*text*/
.text-under{
    color: var(--main-zapp);
    text-decoration: underline;
}
/*collapse*/
.panel-title a:after {
    font-family:Fontawesome;
    content:'\f077';
    float:right;
    font-size:10px;
    font-weight:300;
}
.panel-title a.collapsed:after {
    font-family:Fontawesome;
    content:'\f078';
}

/*modal*/
.modal-content{
    border-radius: 20px;
}
.modal-header.border-bottom-ams{
    padding: 15px !important;
}
.card-header{
    border-bottom: 1px solid var(--cl-bd);
    padding-bottom: 5px;
}
.modal-md{
    max-width: 750px;
}

/*scroll*/
.scroll-content .modal-body{
    max-height: 600px;
    overflow-y: auto;
}
.scroll-content .modal-body::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px #fff;
    background-color: #fff;
}

.scroll-content .modal-body::-webkit-scrollbar
{
    width: 6px;
    background-color: #fff;
}

.scroll-content .modal-body::-webkit-scrollbar-thumb
{
    background: #fff;
}

/*icon*/
.search-box .search-icon{
    max-width: 14px;
    cursor: pointer;
}
.shipment-select{
    line-height: 10px;
    height: 28px;
    border: none;
    border-right: 1px solid  var(--cl-bdd);
    border-radius: unset;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.modal-dialog.modal-dialog-centered{
    min-width: 750px;
}
.entries{
    position: relative;
    margin-top: 22px;
}
.entries select{
    width: 50px;
    border: 1px solid #E2E2EA !important;
    border-radius: 4px !important;
    padding: 4px 10px !important;
    margin-top: -2px;
    background-image: none !important;
    position: relative;
}
.entries:before{
    position: absolute;
    content: "";
    display: block;
    margin: 0px auto;
    width: 6px;
    height: 6px;
    border-top: 1px solid var(--cl-bdd);
    border-left: 1px solid var(--cl-bdd);
    right: 6px;
    top: 7px;
    z-index: 99;
    transform: rotate(225deg);
}
.text-muted{
    color: var(--cl-bdd) !important;
}
.nav-link.All{
    padding-bottom: 8px !important;
}
.search-box{
    margin-top: 15px;
}